import React from 'react'

const GetApp = () => {
    return (
        <div>
            <div className='bg-gray-200 '>
                <div className='flex justify-center w-[80%]  gap-20 m-auto max-md:gap-4 max-md:w-[100%] py-2'>
                    <img src="/assets/qr.png" alt="" className='w-[8rem] max-md:w-[4rem] rounded-xl'/>
                    <h1 className='text-[1.4rem] text-gray-700 font-[600] my-auto max-md:text-[0.8rem]'>Get The App</h1>
                    <img src="/assets/logo.png" alt="" className='my-auto w-[10rem] max-md:w-[5rem] max-md:hidden'/>
                    <a href="https://www.apple.com/app-store/" target="_blank" className='my-auto'>
                        <img src="/assets/app_store_1.png" alt="" className=' w-[10rem] h-[3.5rem] max-md:w-[5rem] max-md:h-[2rem]'/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.jobss.australia" target='_blank' className='my-auto'>
                        <img src="/assets/play_store_1.png" alt="" className=' w-[10rem] h-[3.5rem] max-md:w-[5rem] max-md:h-[2rem]'/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default GetApp
